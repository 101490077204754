
const PrivacyPolicy = () => {
    return (
        <div style={{ margin: "10%" }}>
        <p>At ThrowMyFile, we take your privacy seriously, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose information when you use our application, which allows users to share files across devices using WebSocket.io. Please read this policy carefully to understand our practices regarding your personal data.</p>

        <h2>1. Data Storage</h2>
        
        <p>We do not store any of the files you share through the ThrowMyFile application. The files are transmitted directly from the sender to the receiver without being saved on any of our servers or databases.</p>
        
        <h2>2. Data Sharing</h2>
        
        <p>We do not share your personal information with third parties. Your privacy is essential to us, and we do not disclose any of your data to external parties.</p>
        
        <h2>3. Security</h2>
        
        <p>We take appropriate measures to safeguard your personal information. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>
        
        <h2>4. Cookies</h2>
        
        <p>ThrowMyFile does not use cookies or any other tracking mechanisms to collect your personal information.</p>
        
        <h2>5. Changes to the Privacy Policy</h2>
        
        <p>We may update this Privacy Policy from time to time, and any changes will be posted on this page. Please check this page periodically to stay informed about our privacy practices.</p>
        
        <h2>6. Contact Us</h2>
        
        <p>If you have any questions or concerns about this Privacy Policy or the practices of ThrowMyFile, please contact us at jammmg26@gmail.com</p>
        
        <p><em>Last Updated: July 25, 2023</em></p>
        </div>
    );
    }


export default PrivacyPolicy;