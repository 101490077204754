import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reducers from './reducers';
import reduxThunk from 'redux-thunk';
import axios from 'axios';
import { ThemeProvider } from 'styled-components';
import { light } from '@pancakeswap/uikit';
const container = document.getElementById('root');
const root = createRoot(container);

axios.interceptors.request.use(function (config) {
    const token = `${localStorage.getItem('token')}`;
    config.headers.Authorization = token;

    return config;
});

const store = createStore(
    reducers,
    {
        auth: {
            authenticated: localStorage.getItem('token'),
        },
    },
    composeWithDevTools(applyMiddleware(reduxThunk))
);

root.render(
    <Provider store={store}>
        <ThemeProvider theme={light}>
            <App />
        </ThemeProvider>
    </Provider>
);

// ReactDOM.render(
//    ,
//     document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
